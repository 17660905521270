import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';
import * as tf from '@tensorflow/tfjs-core';


const meanPooling = (modelOutput: tf.Tensor, attentionMask: tf.Tensor) => {
  // TODO: tf tidy
  const tokenEmbeddings = modelOutput
  const expandedInputMask = tf.cast(
      tf.broadcastTo(tf.expandDims(attentionMask, -1), tokenEmbeddings.shape),
      'float32'
  )
  const divident = tf.sum(tf.mul(tokenEmbeddings, expandedInputMask), 1)
  const divisor = tf.clipByValue(tf.sum(expandedInputMask, 1), 1e-9, Infinity)
  return tf.div(divident, divisor)
}

export {
  meanPooling
}
