import React, { useEffect, useState } from 'react';

function SearchBox ({
  search, loading, onChange, onSubmit
}: { search: string, loading: boolean, onChange: (searchStr: string) => any, onSubmit: () => any }) {

  return (
    <div className='block'>
      <form onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
        }}>
        <div className="field has-addons">
          <div className="control" style={{ flex: 1 }}>
            <input 
              className={`input`} 
              type="text" 
              placeholder="Please enter your search here" 
              onChange={(e) => onChange(e.target.value)}
              readOnly={loading}
              value={search}
            />
          </div>
          <div className="control" >
            <button disabled={!search} className={`button is-info ${loading ? 'is-loading' : ''}`} type='submit'>
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SearchBox
