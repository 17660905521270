import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';

import * as use from '@tensorflow-models/universal-sentence-encoder';
import * as tf from '@tensorflow/tfjs-core';
import * as tfconv from '@tensorflow/tfjs-converter';

import { BertTokenizer } from './bert_tokenizer'

import {
  LOCAL_EMBEDDINGS_KEY,
  EMBEDDING_SIZE,
  LOCAL_MODEL_URL,
  REMOTE_MODEL_IS_TFHUB,
  REMOTE_MODEL_URL,
  MODEL_VERSION_KEY,
  LOCAL_TEXTS_KEY
} from '../constants';

import { getLatestVersion } from './download'


import localForage from 'localforage'

const downloadModelToLocal = async () => {
  const model = await tfconv.loadGraphModel(
    REMOTE_MODEL_URL,
    { fromTFHub: REMOTE_MODEL_IS_TFHUB }
  )

  await model.save(LOCAL_MODEL_URL)

  const latestVersion = await getLatestVersion()
  await localForage.setItem(MODEL_VERSION_KEY, latestVersion.modelVersion)
}

const loadModelFromLocal = async (): Promise<use.UniversalSentenceEncoder> => {
  const model = await use.load({
    modelUrl: LOCAL_MODEL_URL
  })

  return model
}

const loadTextDataFromLocal = async (): Promise<object[]> => {
  const textData: object[] = (await localForage.getItem(LOCAL_TEXTS_KEY)) as object[]
  return textData
}

const loadEmbeddingsFromLocal = async (): Promise<tf.Tensor> => {
  const embeddings: Float32Array = (await localForage.getItem(LOCAL_EMBEDDINGS_KEY)) as Float32Array
  console.log(embeddings)
  return tf.reshape(tf.tensor(new Float32Array(embeddings)), [-1, EMBEDDING_SIZE])
}


export {
  loadModelFromLocal,
  loadEmbeddingsFromLocal,
  loadTextDataFromLocal,
  downloadModelToLocal
}
