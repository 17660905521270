import React, { useEffect, useState, useRef } from 'react';
import './App.css';

import ResultCard from './components/ResultCard';
import Pagination from './components/Pagination';
import {
  checkIfDataIsDownloaded,
  downloadTextsAndEmbeddingsToLocal
} from './helpers/download';
import {
  downloadModelToLocal,
  loadEmbeddingsFromLocal,
  loadModelFromLocal,
  loadTextDataFromLocal
} from './helpers/tf';

import { BertTokenizer, loadTokenizer } from './helpers/bert_tokenizer'

import * as tf from '@tensorflow/tfjs-core';
import SearchBox from './components/SearchBox';
import DownloadModal from './components/DownloadModal';
import axios from 'axios';

import { scoreDocuments } from './helpers/score';

interface ResultItem {
  text: string;
  link: string;
  date: string;
}
let TEXT_DATA: ResultItem[]
let TEXT_EMBEDDINGS: tf.Tensor
let TOKENIZER: BertTokenizer
let MODEL: any

function App() {
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    const main = async () => {
      await downloadTextsAndEmbeddingsToLocal()
      await downloadModelToLocal()

      const {data:emb} = await axios.get('https://questionsearch.ticao.de/data/embeddings.json')
      TEXT_EMBEDDINGS = tf.tensor(emb)

      TEXT_DATA = await loadTextDataFromLocal() as ResultItem[]
      // TEXT_EMBEDDINGS = await loadEmbeddingsFromLocal()
      MODEL = await loadModelFromLocal()
      console.log('embeddings',TEXT_EMBEDDINGS)
      console.log(MODEL)
      TOKENIZER = await loadTokenizer()

      const sentences = ['This is an example sentence', 'Each sentence is converted']
      // try {
      //   const tokenizer = await loadTokenizer()
      //   console.log('tokens', tokenizer.tokenize(sentences[0]))
      //   console.log('tokens', tokenizer.tokenize(sentences[1]))

        // console.log(MODEL.tokenizer.encode('embeddings'))
        // tf.tensor([[ 101,   102, ...new Array(121).fill(0) ]], null, 'int32').print(true)
        // const feats =
        //   {
        //     'input_ids': tf.tensor([[ 101, ...tokenizer.tokenize(sentences[0]),  102, ...new Array(121).fill(0) ]], null, 'int32'),
        //     'token_type_ids': tf.tensor([[...new Array(128).fill(0)]], null, 'int32'),
        //     'attention_mask': tf.tensor([[1, 1, 1, 1, 1, 1, 1, ...new Array(121).fill(0)]], null, 'int32')
        //   }
        // feats['input_ids'].print(true)
        // const test = MODEL.model.predict(
        //   feats
        // )
        // console.log(test)
        // test[0].print()
        // test[1].print()
      // } catch(err) {
        // console.error(err)
      // }

      setHits(new Array(TEXT_DATA.length).fill(0).map((v,i) => i))
    }

    main()
  }, [])



  const [search, setSearch] = useState('')
  const [resultsSearch, setResultsSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [hits, setHits] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    if (!loading) {
      return
    }

    if (resultsSearch && search === resultsSearch) {
      setLoading(false)
      return
    }

    const getSearchResults = async () => {
      try {
        const results = await scoreDocuments(
          search,
          MODEL,
          TOKENIZER,
          TEXT_EMBEDDINGS
        )

        setHits(results)
        setResultsSearch(search)
        setLoading(false)
        setPage(1)
      } catch(err) {
        console.error(err)
        alert('Error happened')
        setLoading(false)
      }
    }

    getSearchResults()
  }, [loading])

  const resultsTop: any = useRef()

  useEffect(() => {
    if (!resultsTop || !resultsTop.current || !resultsTop.current) return
    resultsTop.current.scrollIntoView({ behavior: 'smooth' })

  }, [page])

  const maxPage = Math.floor((TEXT_DATA?.length || 0) / pageSize)

  return (
    <div className="App">
      <DownloadModal />
      <div className="columns is-desktop m-0">
        <div className="column" />
        <div className="column is-half-desktop">
          <SearchBox
            search={search}
            loading={loading}
            onChange={(val) => setSearch(val)}
            onSubmit={() => setLoading(true)}
          />

          {resultsSearch && <p className='m-0' ref={resultsTop}>Showing results for "{resultsSearch}"</p>}

          <Pagination
            page={page}
            maxPage={maxPage}
            onChange={(newPage) => setPage(newPage)}
          />
          
          {hits && hits.slice((page-1)*pageSize, page*pageSize).map((hit, i) => <ResultCard
            item={TEXT_DATA[hit]}
            key={`${hit}-${TEXT_DATA[hit]['link']}`}
          />)}
          <Pagination
            page={page}
            maxPage={maxPage}
            onChange={(newPage) => setPage(newPage)}
          />
        </div>
        <div className="column" />
      </div>



      <div className="columns">
      </div>
    </div>
  );
}

export default App;
