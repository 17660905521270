import React, { useEffect, useState } from 'react';

function DownloadModal () {
  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className='card'>
          <div className='card-content'>

          </div>
        </div>
      </div>
      {/* <button className="modal-close is-large" aria-label="close"></button> */}
    </div>
  )
}

export default DownloadModal