import React, { useEffect, useState } from 'react';

function ResultCard ({
  page, maxPage, onChange
}: { page: number, maxPage: number, onChange: (newPage: number) => any }) {

  if (!page || maxPage <= page) return null

  return (
    <nav className="block pagination" role="navigation" aria-label="pagination">
      <button
      style={{ order: 1 }}
      className="pagination-previous"
      disabled={page <= 1}
      onClick={() => onChange(page - 1)}>Previous</button>
      <span style={{ order: 2 }}>
       page <b>{page}</b> of <b>{maxPage}</b>
      </span>
      <button
      style={{ order: 3 }}
      className="pagination-next"
      disabled={page >= maxPage}
      onClick={() => onChange(page + 1)}>Next page</button>
    </nav>
  )
}

export default ResultCard