import axios from 'axios'
import localForage from 'localforage'

import {
  DATA_VERSION_KEY,
  LOCAL_EMBEDDINGS_KEY,
  LOCAL_TEXTS_KEY,
  REMOTE_EMBEDDINGS_URL,
  REMOTE_TEXTS_URL
} from '../constants'

const downloadTextsAndEmbeddingsToLocal = async () => {
  let currentTextData = (await localForage.getItem(LOCAL_TEXTS_KEY)) as object[]
  let currentEmbeddings = (await localForage.getItem(LOCAL_EMBEDDINGS_KEY)) as Float32Array

  try {
    const latestVersion = await getLatestVersion()
    const { data: textsJsonData } = await axios.get(REMOTE_TEXTS_URL)
    const { data: embeddingsRaw } = await axios.get(REMOTE_EMBEDDINGS_URL, {
      responseType: 'arraybuffer'
    })
    await localForage.setItem(LOCAL_TEXTS_KEY, textsJsonData)
    await localForage.setItem(LOCAL_EMBEDDINGS_KEY, new Float32Array(embeddingsRaw))
    await localForage.setItem(DATA_VERSION_KEY, latestVersion.dataVersion)
  } catch (err) {
    // restore original
    if (currentTextData) {
      await localForage.setItem(LOCAL_TEXTS_KEY, currentTextData)
    }
    if (currentEmbeddings) {
      await localForage.setItem(LOCAL_EMBEDDINGS_KEY, currentEmbeddings)
    }

    throw new Error('Failed to download.')
  }
}

const checkIfDataIsDownloaded = async (): Promise<boolean> => {
  const textData: object[] = (await localForage.getItem(LOCAL_TEXTS_KEY)) as object[]
  const embeddings: Float32Array = (await localForage.getItem(LOCAL_EMBEDDINGS_KEY)) as Float32Array

  return textData && textData.length > 0 && embeddings && embeddings.length > 0
}

const checkIfDataUpdateAvailable = async () => {
  const latestVersion = await getLatestVersion()
  const localVersion = await localForage.getItem(DATA_VERSION_KEY)
  return latestVersion.dataVersion === localVersion
    ? latestVersion.dataSize
    : 0
}

const checkIfModelUpdateAvailable = async () => {
  const latestVersion = await getLatestVersion()
  const localVersion = await localForage.getItem(DATA_VERSION_KEY)
  return latestVersion.modelVersion === localVersion
    ? latestVersion.modelSize
    : 0
}

const getLatestVersion = async () => {
  const { data: version } = await axios.get(REMOTE_TEXTS_URL)
  return version
}

export {
  getLatestVersion,
  checkIfModelUpdateAvailable,
  checkIfDataIsDownloaded,
  checkIfDataUpdateAvailable,
  downloadTextsAndEmbeddingsToLocal
}