import React, { useEffect, useState } from 'react';

function ResultCard ({
  item
}: { item: any }) {

  const date = new Date(Date.parse(item.date))
  const dateFormatted = date.toLocaleDateString("en-US", {
    year: 'numeric', month: 'long', day: 'numeric'
  })

  return (
    <div className="card block">
      <div className="card-content">
        <div className="content">
          <div className='columns'>
            <div className='column is-four-fifths'>
              <p>{item.text}</p>
              <time dateTime={item.date}>{dateFormatted}</time>
            </div>
            <div className='column'>
              <a className='open-in-new-link' target='_blank' href={item.link}>
                <span className="icon">
                  <img src="/icons/external.svg" />
                </span>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ResultCard